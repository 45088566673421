header {
    height:100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* ************** CTA ************** */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ************** HEADER SOCIALS ************* */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}


.header__socials::before{
    content: '';
    width: 1.5px;
    height: 4rem;
    background: var(--color-primary-variant);
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 5rem;
    background: var(--color-primary);
}

/* ************** ME ************* */

.me{
    background: linear-gradient(var(--color-bg-variant),var(--color-primary));
    width: 22rem;
    height: auto;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 5rem;
    border-radius: 5rem 5rem 5rem 5rem;
    overflow: hidden;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

/* ************** SCOLL DOWN ************* */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/* ******************MEIDA QUERIES (MEDIUM DEVICES) ************** */

@media screen and (max-width: 1024px){
    header{
        height: 68vh;
    }
}

/* ******************MEIDA QUERIES (SMALL DEVICES) ************** */

@media screen and (max-width: 600px){
    header{
        height: 100vh;
    }
    .header__socials, .scrool__down{
        display: none;
    }
}
